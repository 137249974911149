(function () {
  'use strict';

  angular
      .module('neo.home.persons.person.information')
      .config(config);

  function config($stateProvider) {
    $stateProvider
        .state('home.persons.person.information', {
          url: '/information',
          views: {
            '': {
              templateUrl: 'home/persons/person/information/information.tpl.html',
              controller: 'InformationPersonCtrl',
              controllerAs: 'vm'
            },
            'text@home.persons.person.information': {
              templateUrl: 'home/persons/person/information/text.tpl.html'
            }
          },
          resolve: {
            townHall: function(licenseTeamPerson, TownHallAssociated) {
              return TownHallAssociated.query({id: licenseTeamPerson.organisationId}).$promise;
            },
            currentSeason: function (CurrentSeason) {
              return CurrentSeason.get().$promise;
            },
            team: function (Teams, licenseTeamPerson) {
              return Teams.get({id: licenseTeamPerson.teamId}).$promise;
            },
            foto: function (Foto, licenseTeamPerson) {
              return Foto.query({personId: licenseTeamPerson.person.id}).$promise;
            }
          }
        });
  }
}());
